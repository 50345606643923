import React from "react";


const Footer = () => {
    return (
        <div className="footer default-background" style={{textAlign: 'center',
            borderColor: 'rgba(0, 0, 0, 0.2)',
            padding: '10px', marginTop: '10px'}}>
                    <div>&copy; Autocock.de</div>
                    <div>V0.2</div>
                    <span style={{color: "#666"}}>
                        Made with <span style={{color: "#e25555"}}>&#9829;</span> in Dortmund
                    </span>
        </div>
    )
};

export default Footer;
