import React, {Component} from "react";
import axios from "axios";

import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";

import LocalizedStrings from "react-localization";
import {data} from "./languageData";

let strings = new LocalizedStrings({data});

class IngredientConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            modalSettings: false,
            popoverOpen: false,
            strings: strings[this.props.language]
        };

        this.toggle = this.toggle.bind(this);
        this.toggleSettings = this.toggleSettings.bind(this);
        this.togglePopover = this.togglePopover.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        // Desired operations: ex setting state
        if (prevProps.language !== this.props.language) {
            this.setState({strings: strings[this.props.language]})
        }
    }

    async handleClick() {
        let alcohol = document.getElementById('alcohol_' + this.props.ingredient.id).value;

        if(alcohol.length === 0 || alcohol.toString() === this.props.ingredient.alcohol.toString()) {
            return
        }
        alcohol = parseInt(alcohol);

        this.updatePump(this.props.ingredient.id, alcohol).then(
            () => this.props.updateHandler()
        );
    }

    async updatePump(ingredientId, alcohol) {
        await axios.put(process.env.REACT_APP_SERVER_MODE +
            process.env.REACT_APP_SERVER_ADDRESS +
            process.env.REACT_APP_SERVER_PORT +
            '/api/baseingredient/' + ingredientId.toString(),
            {
                alcohol: alcohol,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        ).then(response => {
            console.log(response);
        }).catch(error => console.log(error));
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleSettings() {
        this.setState({
            modalSettings: !this.state.modalSettings
        });
    }

    togglePopover() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    render() {
        return (
            <div>
                <a href={'#' + this.props.ingredient.id} onClick={this.toggle} alt="" className="btn">Edit</a>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Ingredient {this.props.ingredient.name}</ModalHeader>
                    <ModalBody>
                        <div>
                            {this.state.strings.alcohol}:
                            <Input type="text" id={'alcohol_' + this.props.ingredient.id} placeholder={this.props.ingredient.alcohol}/>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(event) => {
                            this.handleClick().then(() => this.toggle());
                        }}>{this.state.strings.save}</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>{this.state.strings.cancel}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default IngredientConfig;
