import React, {Component} from 'react';

import '../../App.css';

import {
    ListGroupItem,
    Container
} from 'reactstrap';
import axios from "axios";

import IngredientConfig from "./IngredientConfig";

import './ingredients.css';

import LocalizedStrings from 'react-localization';
import { data } from './languageData.js'
let strings = new LocalizedStrings({data});

const Ingredient = ({ingredient, language, updateHandler}) => {
    return (
        <div className="ingredient-item">
            <span className="id">
                {ingredient.id}
            </span>
            <div className="cutstring">
                {ingredient.name}
            </div>
            <div>
                {ingredient.alcohol}
            </div>
            <div>
                <IngredientConfig ingredient={ingredient} language={language} updateHandler={updateHandler}/>
            </div>
        </div>
    )
};

class Ingredients extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ingredients: [],
            strings: strings[this.props.language]
        };

        // Configure functions
        this.setDefaultState = this.setDefaultState.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.updateHandler = this.updateHandler.bind(this);
    }

    componentDidMount() {
        this.setDefaultState()
    }

    componentDidUpdate(prevProps){
        // Desired operations: ex setting state
        if(prevProps.language !== this.props.language) {
            this.setState({strings: strings[this.props.language]})
        }
    }

    async updateIngredientList() {

        axios
            .get(process.env.REACT_APP_SERVER_MODE +
                process.env.REACT_APP_SERVER_ADDRESS +
                process.env.REACT_APP_SERVER_PORT +
                "/api/baseingredient"
            ).then(response => {
                this.setState({
                    ingredients: response.data
                });
            })
            .catch(error => console.log(error));
    }

    setDefaultState() {
        this.setState({
            ingredients: []
        });
        this.updateHandler();
    }

    updateHandler() {
        this.updateIngredientList();
    }

    render() {
        let ingredients = this.state.ingredients;

        return (
            <div style={{paddingTop: '20px'}}>
                <Container className="box">
                        <div className="ingredient-box">
                            <ListGroupItem className="justify-content-between">
                                <div className="ingredient-item">
                                    <span className="id">
                                        ID
                                    </span>
                                    <div>
                                        {this.state.strings.ingredient}
                                    </div>
                                    <div>
                                        {this.state.strings.alcohol}
                                    </div>
                                </div>
                            </ListGroupItem>
                            {
                                ingredients.map(function (ingredient, i) {
                                    return (
                                        <ListGroupItem key={"ListGroupItemPump" + ingredient.id}
                                                       className="justify-content-between">
                                            <Ingredient key={"Ingredient" + ingredient.id}
                                                        language={this.props.language}
                                                        ingredient={ingredient}
                                                        updateHandler={this.updateHandler}/>
                                        </ListGroupItem>
                                    );
                                }, this)
                            }
                        </div>
                    {/*<Button onClick={this.toggleModal}>{this.state.strings.newpump}</Button>*/}
                </Container>
            </div>
        )
    }
}

export default Ingredients;
