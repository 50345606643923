import React, {Component} from 'react';

import '../../App.css';

import {
    Button,
    Col,
    ListGroupItem,
    ListGroup,
    Progress,
    Input,
    Container,
    Row,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    FormGroup,
    Label
} from 'reactstrap';
import axios from "axios";

import CocktailIngredientSelector from './CocktailIngredientSelector';

import LocalizedStrings from 'react-localization';
import { data } from './languageData.js'
let strings = new LocalizedStrings({data});


class CocktailBuilder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ingredients: [],
            amount: 0,
            ingredientSelection: [null, null, null, null, null, null, null, null, null, null],
            ingredientAmount: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            modal: false,
            cocktailName: '',
            strings: strings[this.props.language]
        };


        // Configure functions
        this.changeAmount = this.changeAmount.bind(this);
        this.buildCocktail = this.buildCocktail.bind(this);
        this.handleIngredientSelectionChange = this.handleIngredientSelectionChange.bind(this);
        this.handleIngredientAmountChange = this.handleIngredientAmountChange.bind(this);

        this.toggleModal = this.toggleModal.bind(this);
        this.getName = this.getName.bind(this);
        this.updateCocktailName = this.updateCocktailName.bind(this);
        this.setDefaultState = this.setDefaultState.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
    }

    componentDidMount() {
        this.setDefaultState()
    }

    componentDidUpdate(prevProps){
        // Desired operations: ex setting state
        if(prevProps.language !== this.props.language) {
            this.setState({strings: strings[this.props.language]})
        }
    }
    /**
     * Updates the current list of ingredients
     */
    async updateIngredientList() {

        axios
            .get(process.env.REACT_APP_SERVER_MODE +
                process.env.REACT_APP_SERVER_ADDRESS +
                process.env.REACT_APP_SERVER_PORT +
                "/api/baseingredient/active"
            ).then(response => {

            const newIngredients = response.data.map(c => {
                return {
                    id: c.id,
                    name: c.name,
                    alcohol: c.alcohol,
                };
            });

            const newState = Object.assign({}, this.state, {
                ingredients: newIngredients
            });

            this.setState(newState);
        })
            .catch(error => console.log(error));
    }

    setDefaultState() {
        this.setState({
            ingredients: [],
            amount: 0,
            ingredientSelection: [null, null, null, null, null, null, null, null, null, null],
            ingredientAmount: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            modal: false,
            cocktailName: ''
        });

        this.updateIngredientList();
    }

    handleIngredientSelectionChange(ingredientIndex, newValue) {
        // Clone array
        let ingredientSelection = this.state.ingredientSelection.slice(0);
        // Set new value
        ingredientSelection[ingredientIndex] = newValue;
        console.log(ingredientIndex, newValue);
        // Update state value
        this.setState({ingredientSelection: ingredientSelection});
    }

    handleIngredientAmountChange(ingredientIndex, newValue) {
        // Clone array
        let ingredientAmount = this.state.ingredientAmount.slice(0);
        // Set new value
        ingredientAmount[ingredientIndex] = newValue;
        console.log(ingredientIndex, newValue);
        // Update state value
        this.setState({ingredientAmount: ingredientAmount});
    }

    changeAmount(stepsize) {
        if (this.state.amount + stepsize <= 100 && this.state.amount + stepsize > -0.5) {
            this.setState({
                amount: this.state.amount + stepsize
            });
            return true;
        }
        return false;
    }

    buildCocktail() {

        if (this.state.cocktailName === "") {
            alert(this.state.strings.chooseName);
            return;
        }

        let data = [];
        for (let i = 0; i < 10; i++) {
            if (this.state.ingredientSelection[i] != null) {
                if (this.state.ingredientAmount[i] > 0.5) {
                    data.push({id: this.state.ingredientSelection[i], amount: this.state.ingredientAmount[i]})
                } else {
                    continue;
                }
            }
        }

        // Check for data
        if (data.length === 0) {
            alert(this.state.strings.pleaseChooseAndSet);
            this.toggleModal();
            return;
        }

        axios
            .post(process.env.REACT_APP_SERVER_MODE +
                process.env.REACT_APP_SERVER_ADDRESS +
                process.env.REACT_APP_SERVER_PORT +
                "/api/cocktail",
                {
                    cocktail: data,
                    cocktailName: this.state.cocktailName
                },
            ).then(response => {
            console.log(response);
            this.setState({
                cocktailName: ""
            });

            alert('Cocktail created.');
            this.toggleModal();
            this.setDefaultState();
        })
            .catch(error => {
                console.log(error);
                alert(error.response.data['Error']);
            });
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    getName(id) {
        for (var i = 0; i < this.state.ingredients.length; i++) {
            if (this.state.ingredients[i].id === id) {
                return this.state.ingredients[i].name;
            }
        }
    }

    updateCocktailName(evt) {
        this.setState({
            cocktailName: evt.target.value
        });
    }

    render() {
        return (
            <div style={{paddingTop: '20px'}}>
                <Container className="box">
                    <Row>
                        <Col>
                            <ListGroup flush>
                                {
                                    this.state.ingredients.map(function (list, i) {
                                        return (
                                            <CocktailIngredientSelector id={i} ingredients={this.state.ingredients}
                                                                changeAmount={this.changeAmount}
                                                                onIngredientChange={this.handleIngredientSelectionChange}
                                                                onIngredientAmountChange={this.handleIngredientAmountChange}
                                            />
                                        );
                                    }, this)
                                }
                            </ListGroup>

                            <div style={{padding: '10px'}}>
                                <Progress animated value={this.state.amount} max={100}>{this.state.amount}%</Progress>
                            </div>

                            <Button onClick={this.toggleModal}>{this.state.strings.build}</Button>

                            <Modal isOpen={this.state.modal} toggle={this.toggleModal} className={this.props.className}>
                                <ModalHeader toggle={this.toggleModal}>{this.state.strings.checkSelection}</ModalHeader>
                                <ModalBody>
                                    {this.state.strings.cocktailContains}:
                                    <ListGroup flush>
                                        {
                                            this.state.ingredientSelection.map(function (ingredient, i) {
                                                return (
                                                    <ListGroupItem
                                                        className="justify-content-between">{this.getName(ingredient)} {this.state.ingredientAmount[i] / 100.0 * 250} ml /  {this.state.ingredientAmount[i]} %
                                                    </ListGroupItem>
                                                );
                                            }, this)
                                        }
                                    </ListGroup>
                                    <br/>
                                    <FormGroup>
                                        <Label for="cocktailName">{this.state.strings.chooseName}:</Label>
                                        <Input type="text" onChange={evt => this.updateCocktailName(evt)}
                                               id="cocktailName" placeholder="MyCocktail" required
                                               value={this.state.cocktailName}/>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={(event) => {
                                        this.buildCocktail();
                                    }}>{this.state.strings.createCocktail}</Button>{' '}
                                    <Button color="secondary" onClick={this.toggleModal}>{this.state.strings.cancel}</Button>
                                </ModalFooter>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default CocktailBuilder;
