import React, {Component} from "react";
import axios from "axios";

import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";

import LocalizedStrings from "react-localization";
import {data} from "./languageData";

let strings = new LocalizedStrings({data});

class PumpConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            modalSettings: false,
            popoverOpen: false,
            strings: strings[this.props.language]
        };

        this.toggle = this.toggle.bind(this);
        this.toggleSettings = this.toggleSettings.bind(this);
        this.togglePopover = this.togglePopover.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        // Desired operations: ex setting state
        if (prevProps.language !== this.props.language) {
            this.setState({strings: strings[this.props.language]})
        }
    }

    async handleClick() {
        let flowRate = document.getElementById('flowrate_' + this.props.pump.id).value;
        let ingredient = document.getElementById('ingredient_' + this.props.pump.id).value;
        if(flowRate.length === 0) {
            flowRate = this.props.pump.flow_rate.toString();
        }

        if(ingredient === this.props.pump.base_ingredient.toString() && flowRate === this.props.pump.flow_rate.toString()) {
            return
        }

        this.updatePump(this.props.pump.id, flowRate, ingredient).then(
            () => this.props.updateHandler()
        );
    }

    async updatePump(pumpId, flowRate, ingredient) {
        await axios.put(process.env.REACT_APP_SERVER_MODE +
            process.env.REACT_APP_SERVER_ADDRESS +
            process.env.REACT_APP_SERVER_PORT +
            '/api/pump/' + pumpId.toString(),
            {
                flow_rate: flowRate,
                base_ingredient: ingredient
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        ).then(response => {
            console.log(response);
        }).catch(error => console.log(error));
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleSettings() {
        this.setState({
            modalSettings: !this.state.modalSettings
        });
    }

    togglePopover() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    render() {
        let ingredients = this.props.ingredients;

        let ingredientOptions = [];
        Object.keys(ingredients).forEach(function (key, i) {
            ingredientOptions.push(
                <option key={this.props.pump + "_" + key} value={key}>{this.props.ingredients[key].name}</option>
            );
        }, this);

        return (
            <div>
                <a href={'#' + this.props.pump.id} onClick={this.toggle} alt="" className="btn">Edit</a>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Pump {this.props.pump.id}</ModalHeader>
                    <ModalBody>
                        <div>
                            {this.state.strings.ingredient}
                            <Input type="select" id={'ingredient_' + this.props.pump.id}>
                                <option value={this.props.pump.base_ingredient}>
                                    {
                                        Object.keys(ingredients).length > 0 ?
                                            ingredients[this.props.pump.base_ingredient].name :
                                            this.props.pump.base_ingredient
                                    }
                                </option>
                                {
                                    ingredientOptions
                                }
                            </Input>
                        </div>
                        <div>
                            {this.state.strings.flowRate}:
                            <Input type="text" id={'flowrate_' + this.props.pump.id} placeholder={this.props.pump.flow_rate}/>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(event) => {
                            this.handleClick().then(() => this.toggle());
                        }}>{this.state.strings.save}</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>{this.state.strings.cancel}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default PumpConfig;
