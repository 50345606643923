import React from "react";
import {Container} from "reactstrap";

const Service = () => {

    return (
        <div style={{paddingTop: '20px'}}>
            <Container className="box">
            Service
            <div>
                CleanUp all pumps: <input type="submit" value="Start"/>
            </div>
            <div>
                CleanUp pump: <input type="text"/> <input type="submit" value="Start"/>
            </div>
            </Container>
        </div>
    )
};

export default Service;
