import React, {Component} from 'react';
import '../../App.css';

import axios from 'axios';
import {CardColumns, Navbar} from 'reactstrap';

import ReactPaginate from 'react-paginate';

import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/ru';

import LocalizedStrings from 'react-localization';
import {data} from './languageData.js'

import {library} from '@fortawesome/fontawesome-svg-core'
import {faUsers} from '@fortawesome/free-solid-svg-icons'

import CocktailCard from './CocktailCard';

library.add(faUsers);

let strings = new LocalizedStrings({data});


class CocktailView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cocktails: [],
            strings: strings[this.props.language],
            currentPage: 1,
            cocktailPerPage: 6,
        };

        this.sortCocktails = this.sortCocktails.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.handlePageChangeClick = this.handlePageChangeClick.bind(this);
    }

    componentDidUpdate(prevProps){
        // Desired operations: ex setting state
        if(prevProps.language !== this.props.language) {
            this.setState({strings: strings[this.props.language]})
        }
    }

    componentDidMount() {
        axios
            .get(process.env.REACT_APP_SERVER_MODE +
                process.env.REACT_APP_SERVER_ADDRESS +
                process.env.REACT_APP_SERVER_PORT +
                '/api/cocktail'
            ).then(response => {

            const newCocktails = response.data.map(c => {
                let alcohol = 0;
                for (let i = 0; i < c.ingredients.length; i++) {
                    alcohol += c.ingredients[i].amount * c.ingredients[i].base_ingredient.alcohol;
                }

                return {
                    id: c.id,
                    name: c.name,
                    orders: c.sum,
                    ingredients: c.ingredients,
                    latest_order: c.latest_order,
                    alcohol: alcohol,
                };
            });

            // create a new "State" object without mutating
            // the original State object.
            const newState = Object.assign({}, this.state, {
                cocktails: newCocktails
            });

            // store the new state object in the component's state
            this.setState(newState);
        })
            .catch(error => console.log(error));
    }

    sortCocktails(evt) {

        let cocktails = this.state.cocktails;

        // Modify cocktail order based on input type
        if(evt.target.id === "shuffle") {
            for (let i = cocktails.length - 1; i > 0; i--) {
                let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
                [cocktails[i], cocktails[j]] = [cocktails[j], cocktails[i]]; // swap elements
            }
        }
        else if(evt.target.id === "ordersASC") {
            cocktails.sort((a, b) => parseFloat(a.orders) - parseFloat(b.orders));
        }
        else if(evt.target.id === "ordersDESC") {
            cocktails.sort((a, b) => parseFloat(b.orders) - parseFloat(a.orders));
        }
        else if(evt.target.id === "alcoholASC") {
            cocktails.sort((a, b) => parseFloat(a.alcohol) - parseFloat(b.alcohol));
        }
        else if(evt.target.id === "alcoholDESC") {
            cocktails.sort((a, b) => parseFloat(b.alcohol) - parseFloat(a.alcohol));
        }

        this.setState({cocktails: cocktails})
    }

    handlePageChangeClick(event) {
        this.setState({
            currentPage: Number(event.selected) + 1
        });
    }

    render() {

        let cocktails = this.state.cocktails;
        const currentPage = this.state.currentPage;

        // Logic for displaying current cocktails
        const indexOfLastCocktail = currentPage * this.state.cocktailPerPage;
        const indexOfFirstCocktail = indexOfLastCocktail - this.state.cocktailPerPage;
        const currentCocktails = cocktails.slice(indexOfFirstCocktail, indexOfLastCocktail);

        let CocktailCards = [];
        currentCocktails.forEach(function (cocktail) {
            CocktailCards.push(<CocktailCard key={cocktail.id + cocktail.name} cocktail={cocktail} language={this.props.language}/>)
        }, this);

        return (
            <div>
                    <div>
                        <Navbar color="dark" expand="md" style={{fontSize: 'small'}}>
                            <div>
                                <div id="ordersASC" onClick={this.sortCocktails} className="nav-link">{this.state.strings.orders} &#9650;</div>
                                <div id="ordersDESC" onClick={this.sortCocktails} className="nav-link">{this.state.strings.orders} &#9660;</div>
                            </div>
                            <div>
                                <div id="alcoholASC" onClick={this.sortCocktails} className="nav-link">{this.state.strings.alcohol} &#9650;</div>
                                <div id="alcoholDESC" onClick={this.sortCocktails} className="nav-link">{this.state.strings.alcohol} &#9660;</div>
                            </div>
                            <div>
                                <div id="shuffle" onClick={this.sortCocktails} className="nav-link">{this.state.strings.shuffle}</div>
                            </div>
                        </Navbar>
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={Math.ceil(cocktails.length / this.state.cocktailPerPage)}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={this.handlePageChangeClick}
                            containerClassName={'paginationbar'}
                            subContainerClassName={'paginationbar'}
                            activeClassName={'active'}
                        />
                    </div>

                <CardColumns>
                    {CocktailCards}
                </CardColumns>
            </div>
        )
    }
}


export default CocktailView;
