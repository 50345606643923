import React, {Component} from "react";
import {Button, Col, Input, InputGroup, InputGroupAddon, ListGroupItem, Row} from "reactstrap";

class CocktailIngredientSelector extends Component {
    constructor(props) {
        super(props);

        this.plus = this.plus.bind(this);
        this.minus = this.minus.bind(this);
        this.stepsize = this.stepsize.bind(this);
        this.state = {
            amount: 0,
            alcohol: -1
        }
    }

    stepsize() {
        let alcohol = this.state.alcohol;

        if (alcohol >= 21) {
            return 1;
        } else if (alcohol >= 11) {
            return 2;
        } else if (alcohol < 0) {
            return 0;
        } else {
            return 5;
        }
    }

    plus() {
        let new_amount = this.state.amount;

        if (this.state.amount + this.stepsize() < 101) {
            if (this.props.changeAmount(this.stepsize())) {
                new_amount = new_amount + this.stepsize()
            }
        }

        // Update parent component
        this.props.onIngredientAmountChange(this.props.id, new_amount);

        this.setState({
            amount: new_amount
        });
    }

    minus() {

        let new_amount = this.state.amount;

        if (this.state.amount - this.stepsize() > -0.5) {
            if (this.props.changeAmount(-this.stepsize())) {
                new_amount = new_amount - this.stepsize()
            }
        } else {
            new_amount = 0;
        }

        // Update parent component
        this.props.onIngredientAmountChange(this.props.id, new_amount);

        this.setState({
            amount: new_amount
        });
    }

    getId(name) {
        for (let i = 0; i < this.props.ingredients.length; i++) {
            if (this.props.ingredients[i].name === name) {
                return i;
            }
        }
    }

    getAlcohol(id) {
        if (id == null) {
            return -1;
        }

        return this.props.ingredients[id].alcohol;
    }

    handleChange = (event) => {
        if (event.target.value === '-') {
            this.props.onIngredientChange(this.props.id, null)
            return;
        }

        let id = this.getId(event.target.value);
        // Update parent component
        this.props.onIngredientChange(this.props.id, this.props.ingredients[id].id);
        this.setState({alcohol: this.getAlcohol(id)});
    }


    render() {
        return (
            <ListGroupItem style={{backgroundColor: 'rgba(255, 255, 255, 0.8)', borderColor: 'rgba(0, 0, 0, 0.2)'}}>
                <Row>
                    <Col xs="5">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Button size="sm" onClick={this.minus}>-</Button>
                            </InputGroupAddon>
                            <Input placeholder="0%" readOnly value={this.state.amount + ' %'}/>
                            <InputGroupAddon addonType="append">
                                <Button size="sm" onClick={this.plus}>+</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    <Col xs="2">
                        {this.state.amount / 100.0 * 250}ml
                    </Col>
                    <Col xs="5">
                        <Input type="select" name="selection" onChange={this.handleChange}>
                            <option>-</option>
                            {
                                this.props.ingredients && this.props.ingredients.map((list) => {
                                    return <option id={list.id}>{list.name}</option>
                                })
                            }
                        </Input>
                    </Col>
                </Row>
            </ListGroupItem>
        )
    }
}

export default CocktailIngredientSelector;
