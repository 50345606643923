import React, {Component} from 'react';

import {
    Container
} from 'reactstrap';

import './connect.css';

import LocalizedStrings from 'react-localization';
import { data } from './languageData.js'
let strings = new LocalizedStrings({data});

class Connect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            strings: strings[this.props.language]
        };

        // Configure functions
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps){
        // Desired operations: ex setting state
        if(prevProps.language !== this.props.language) {
            this.setState({strings: strings[this.props.language]})
        }
    }

    render() {
        //https://de.depositphotos.com/153404214/stock-photo-glass-of-green-cocktail-with.html
            return (
                <Container className="connect-box">
                    <div className="connect-title">
                        Welcome to AutoCock.de
                    </div>
                    <div className="connect-text">
                        Connect with your machine to get started:
                    </div>
                    <div>
                        <div className="connect-item">
                            <div>Event ID:</div>
                            <div><input type="text" placeholder="#AC1DC" style={{width: "100%"}}/></div>
                        </div>
                        <div className="connect-item">
                            <div>Tag ID:</div>
                            <div><input type="text" placeholder="#GTCAE" style={{width: "100%"}}/></div>
                        </div>
                        <div className="connect-item">
                            <div> </div>
                            <div><button type="button" style={{width: "100%"}} onClick={this.props.connect}>Connect</button></div>
                        </div>
                    </div>
                </Container>
            )
        }
}

export default Connect;
