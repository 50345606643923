import React, {Component} from 'react';

import './App.css';

import {
    Container
} from 'reactstrap';

import {BrowserRouter as Router, Route} from "react-router-dom";

import CocktailView from './components/CocktailView/CocktailView';
import CocktailBuilder from './components/CocktailBuilder/CocktailBuilder';
import Feedback from './components/Feedback/Feedback'
import Changelog from './components/About/Changelog'
//import Statistics from './components/Statistics'
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import Pumps from "./components/Pump/Pumps";
import Ingredients from "./components/Ingredient/Ingredients";
import Service from './components/Service/Service'

import Connect from './components/Connect/Connect';

const Background = require('./cocktaildark.jpg');

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language: 'en',
            machineToken: ''
        };

        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.getLanguage = this.getLanguage.bind(this);
        this.connect = this.connect.bind(this);
        this.disconnect = this.disconnect.bind(this);
        this.updateFromStorage = this.updateFromStorage.bind(this);
    }

    componentDidMount() {
        this.updateFromStorage();
        window.addEventListener('storage', this.updateFromStorage);
    }

    updateFromStorage() {
        let oldData = localStorage.getItem('AutoCock');
        if(oldData) {
            this.setState({
                machineToken: oldData
            })
        } else {
            this.disconnect();
        }
    }

    getLanguage() {
        return this.state.language;
    }

    handleLanguageChange(e) {
        e.preventDefault();
        let lang = e.target.value;
        this.setState(prevState => ({
            language: lang
        }))
    }

    connect() {
        localStorage.setItem('AutoCock', 'value');
        this.setState({
            machineToken: 'value'
        })
    }

    disconnect() {
        localStorage.removeItem('AutoCock');
        this.setState({
            machineToken: ''
        })
    }

    render() {
        //https://de.depositphotos.com/153404214/stock-photo-glass-of-green-cocktail-with.html
        if(this.state.machineToken === 'value') {
            console.log("Valid user");
            return (
                <Router>
                    <div className="flex-container"
                         style={{background: "black url(" + Background + ") no-repeat center 0 fixed"}}>
                        <Header handleLanguageChange={this.handleLanguageChange} handleDisconnect={this.disconnect} language={this.state.language}/>
                        <Container className="content">
                            <Route exact path='/' render={() => <CocktailView language={this.state.language}/>}/>
                            <Route exact path='/build'
                                   render={() => <CocktailBuilder language={this.state.language}/>}/>
                            <Route exact path='/pump' render={() => <Pumps language={this.state.language}/>}/>
                            <Route exact path='/ingredient' render={() => <Ingredients language={this.state.language}/>}/>
                            <Route exact path='/service' render={() => <Service language={this.state.language}/>}/>
                            <Route exact path='/feedback' render={() => <Feedback language={this.state.language}/>}/>
                            <Route exact path='/changelog' render={() => <Changelog language={this.state.language}/>}/>
                            {/*<Route exact path='/statistics' render={() => <Statistics language={this.state.language}/>}/>*/}
                        </Container>
                        <Footer/>
                    </div>
                </Router>
            );
        } else {
            return (
                <div className="flex-container"
                     style={{background: "black url(" + Background + ") no-repeat center 0 fixed"}}>
                    <Container className="content">
                        <Connect connect={this.connect}/>
                    </Container>
                    <Footer/>
                </div>
            )
        }
    }
}

export default App;
