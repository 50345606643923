const data = {
    en:{
        satisfaction:"Satisfaction",
        rateProduct: "Please rate our product",
        overall: "Overall",
        interested: "Interested? Leave your E-Mail for more information!",
        moreFeedback: "You have some more feedback or ideas? Let us know!",
        name: "Your name for possible queries?",
        machine: "Machine",
        software: "Software",
        cocktail: "Cocktail",
        submit: "Submit",
    },
    de: {
        satisfaction:"Zufriedenheit",
        rateProduct: "Bitte bewerte unser Produkt",
        overall: "Insgesamt",
        interested: "Interessiert? Lass deine E-Mail hier für weitere Informationen!",
        moreFeedback: "Du hast weiteres Feedback oder Ideen? Lass es uns wissen.",
        name: "Dein Name für eventuelle Nachfragen?",
        machine: "Maschine",
        software: "Software",
        cocktail: "Cocktail",
        submit: "Absenden",
    },
    ru: {
        satisfaction:"удовлетворение",
        rateProduct: "Пожалуйста, оцените наш продукт",
        overall: "в целом",
        interested: "Заинтересовались? Оставьте свой адрес электронной почты здесь для получения дополнительной информации!",
        moreFeedback: "У вас есть еще отзывы или идеи? Дайте нам знать.",
        name: "Ваше имя для каких-либо запросов?",
        machine: "машина",
        software: "Программного обеспечения",
        cocktail: "коктейль",
        submit: "Отправить",
    }
};

export{data};
