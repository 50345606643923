const data = {
    en: {
        tryAgainLater: "Please try again later.",
        ingredients: "Ingredients",
        pleaseTakeCare: "Please take care that a glass is inside the machine.",
        order: "Order",
        selectMachine: "Select machine",
        cocktailSize: "Cocktail size",
        cancel: "Cancel",
        orderSuccessful: "Order was received.",
        details: "Details",
        serves: "Orders",
        lastOrder: "Last order",
        noOrder: "No order yet",
        orders: "Orders",
        alcohol: "Alcohol",
        shuffle: "Shuffle",
    },
    de: {
        tryAgainLater: "Bitte versuche es später nochmal.",
        ingredients: "Zutaten",
        pleaseTakeCare: "Bitte überprüfe, ob ein Glas in der Maschine steht.",
        order: "Bestellen",
        selectMachine: "Wähle einen Automaten",
        cocktailSize: "Cocktail Größe",
        cancel: "Abbrechen",
        orderSuccessful: "Die Bestellung wurde entgegengenommen.",
        details: "Details",
        serves: "Bestellungen",
        lastOrder: "Letzte Bestellung",
        noOrder: "Bisher keine Bestellung",
        orders: "Bestellungen",
        alcohol: "Alkohol",
        shuffle: "Mischen",
    },
    ru: {
        tryAgainLater: "Пожалуйста, попробуйте позже.",
        ingredients: "ингредиенты",
        pleaseTakeCare: "Пожалуйста, проверьте, есть ли в машине стекло.",
        order: "заказ",
        selectMachine: "Выберите машину",
        cocktailSize: "Размер коктейля",
        cancel: "выкинуть",
        orderSuccessful: "Заказ был принят.",
        details: "детали",
        serves: "заказы",
        lastOrder: "Последний заказ",
        noOrder: "пока нет заказа",
        orders: "заказы",
        alcohol: "алкоголь",
        shuffle: "шаркать",
    }
};

export {data};
