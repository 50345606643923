const data = {
    en:{
        save:"Save",
        cancel: "Cancel",
        newpump: "Add new pump",
        alcohol: "Alcohol",
        ingredient: "Ingredient"
    },
    de: {
        save:"Speichern",
        cancel: "Abbrechen",
        newpump: "Neue Pumpe hinzufügen",
        alcohol: "Alkohol",
        ingredient: "Zutat"
    },
    ru: {
        build:"составить",
        cocktailContains: "Ваш коктейль включает в себя",
        chooseName: "Пожалуйста, выберите название для вашего коктейля",
        createCocktail: "Создать коктейль",
        cancel: "выкинуть",
        checkSelection: "Проверьте свой выбор",
        pleaseChooseAndSet: "Пожалуйста, выберите ингредиенты и настройте количество"
    }
};

export{data};
