const data = {
    en:{
        build:"Build",
        cocktailContains: "Your cocktail contains",
        chooseName: "Please choose a name for you Cocktail",
        createCocktail: "Create cocktail",
        cancel: "Cancel",
        checkSelection: "Check your selection",
        pleaseChooseAndSet: "Please choose ingredients and set the amount.",
    },
    de: {
        build:"Erstellen",
        cocktailContains: "Dein Cocktail beinhaltet",
        chooseName: "Bitte wähle einen Namen für deinen Cocktail",
        createCocktail: "Cocktail erstellen",
        cancel: "Abbrechen",
        checkSelection: "Überprüfe deine Auswahl",
        pleaseChooseAndSet: "Bitte wähle Zutaten aus und konfiguriere die Menge"
    },
    ru: {
        build:"составить",
        cocktailContains: "Ваш коктейль включает в себя",
        chooseName: "Пожалуйста, выберите название для вашего коктейля",
        createCocktail: "Создать коктейль",
        cancel: "выкинуть",
        checkSelection: "Проверьте свой выбор",
        pleaseChooseAndSet: "Пожалуйста, выберите ингредиенты и настройте количество"
    }
};

export{data};
