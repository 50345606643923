import React, {Component} from "react";
import {
    Collapse, DropdownItem, DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledDropdown
} from "reactstrap";
import {Link} from "react-router-dom";

import LocalizedStrings from 'react-localization';
import { data } from './../../languageData.js'

let strings = new LocalizedStrings({data});

class Header extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.close = this.close.bind(this);
        this.state = {
            isOpen: false,
            strings: strings[this.props.language]
        };

        this.componentDidUpdate = this.componentDidUpdate.bind(this);
    }

    componentDidUpdate(prevProps) {
        // Desired operations: ex setting state
        if (prevProps.language !== this.props.language) {
            this.setState({strings: strings[this.props.language]})
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    close() {
        if (this.state.isOpen) {
            this.setState({
                isOpen: !this.state.isOpen
            });
        }
    }

    render() {
        return (
            <div className="header">
                <Navbar color="dark" dark expand="md" className="navbar">
                    <NavbarBrand href="/">Cocktail Maker</NavbarBrand>
                    <select onChange={this.props.handleLanguageChange}>
                        <option value="en">{this.state.strings.english} </option>
                        <option value="de">{this.state.strings.german}</option>
                        <option value="ru">{this.state.strings.russian}</option>
                    </select>
                    <NavbarToggler onClick={this.toggle}/>
                    <Collapse  isOpen={this.state.isOpen} navbar>
                        <Nav className="mr-auto" navbar>
                            <NavItem>
                                <NavLink to="/" className="nav-link" tag={Link} onClick={this.close}>{this.state.strings.order}</NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink to="/build" className="nav-link" tag={Link} onClick={this.close}>{this.state.strings.build}</NavLink>
                            </NavItem>

                            {/*<NavItem>

                                    <NavLink to="/statistics" className="nav-link" tag={Link} onClick={this.close}>{this.state.strings.statistics}</NavLink>
                                </NavItem>*/}

                            <UncontrolledDropdown nav inNavbar >
                                <DropdownToggle nav caret>
                                    Configuration
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem>
                                        Cocktails
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavLink to="/ingredient" className="nav-link" tag={Link} onClick={this.close}>Ingredients</NavLink>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavLink to="/pump" className="nav-link" tag={Link} onClick={this.close}>Pumps</NavLink>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        <NavLink to="/service" className="nav-link" tag={Link} onClick={this.close}>Service</NavLink>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    {this.state.strings.about}
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem>
                                        <NavLink to="/changelog" className="nav-link" tag={Link} onClick={this.close}>{this.state.strings.changelog}</NavLink>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <NavItem>
                                <NavLink to="/feedback" className="nav-link" tag={Link} onClick={this.close}>{this.state.strings.feedback}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink to="/" className="nav-link" tag={Link} onClick={this.close && this.props.handleDisconnect}>Disconnect</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        )
    }
}

export default Header;
