import React, {Component} from 'react';
import '../../App.css';

import {Container, Row, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText} from 'reactstrap';

class Changelog extends Component {
    /*constructor(props) {
        super(props);
    }*/

    render() {
        return (
            <div style={{paddingTop: '20px'}}>
                <Container style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderColor: 'rgba(0, 0, 0, 0.2)',
                    padding: '10px'
                }}>
                    <Row>
                        <Col>
                            <h3>Changelog</h3>
                            <ListGroup>
                                <ListGroupItem>
                                    <ListGroupItemHeading>
                                        Version 0.2 - 06/2019
                                    </ListGroupItemHeading>
                                    <ListGroupItemText>
                                        <ListGroupItem>
                                            <ul>
                                                <li>10 Pumps</li>
                                                <li>Performance optimizations</li>
                                                <li>
                                                    Cloud service
                                                </li>
                                                <li>Redesign Software
                                                    <ul>
                                                        <li>Latest technology</li>
                                                    </ul>
                                                </li>
                                                <li>Redesign Hardware
                                                    <ul>
                                                        <li>Latest technology</li>
                                                        <li>3D Printing</li>
                                                        <li>New controller</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </ListGroupItem>
                                    </ListGroupItemText>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <ListGroupItemHeading>
                                        Version 0.1 - 12/2018
                                    </ListGroupItemHeading>
                                    <ListGroupItemText>
                                        <ListGroupItem>
                                            <ul>
                                                <li>6 Pumps</li>
                                                <li>Initial Software</li>
                                                <li>Initial Hardware</li>
                                            </ul>
                                        </ListGroupItem>
                                    </ListGroupItemText>
                                </ListGroupItem>
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}


export default Changelog;
