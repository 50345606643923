const data = {
    en:{
        english: "English",
        german: "Deutsch",
        russian: "русский",
        order: "Order",
        build: "Build",
        about: "About",
        changelog: "Changelog",
        statistics: "Statistics",
        feedback: "Feedback",
    },
    de: {
        english: "English",
        german: "Deutsch",
        russian: "русский",
        order: "Bestellen",
        build: "Erstellen",
        about: "Über",
        changelog: "Änderungen",
        statistics: "Statistiken",
        feedback: "Feedback",
    },
    ru: {
        english: "English",
        german: "Deutsch",
        russian: "русский",
        order: "заказ",
        build: "составить",
        about: "Около",
        changelog: "поправки",
        statistics: "статистика",
        feedback: "обратная связь",
    }
};

export{data};
