import React, {Component} from 'react';

import '../../App.css';
import {Container, Row, Col, Button, Form, FormGroup, Label, Input} from 'reactstrap';

import LocalizedStrings from 'react-localization';
import {data} from './languageData.js'
import axios from "axios";

let strings = new LocalizedStrings({data});

class RatingBuilder extends Component {
    render() {
        return (
            <div>
                <FormGroup check inline>
                    <Label check>
                        <Input type="radio" name={'rating' + this.props.identifier} id={'1'}
                               onClick={this.props.handler}/>{' '}
                        <img src={require('./verybad.png')} alt="Bad" style={{width: '25px'}}/>
                    </Label>
                </FormGroup>
                <FormGroup check inline>
                    <Label check>
                        <Input type="radio" name={'rating' + this.props.identifier} id={'2'}
                               onClick={this.props.handler}/>{' '}
                        <img src={require('./neutral.png')} alt="Neutral" style={{width: '25px'}}/>
                    </Label>
                </FormGroup>
                <FormGroup check inline>
                    <Label check>
                        <Input type="radio" name={'rating' + this.props.identifier} id={'3'}
                               onClick={this.props.handler}/>{' '}
                        <img src={require('./verygood.png')} alt="Good" style={{width: '25px'}}/>
                    </Label>
                </FormGroup>
            </div>
        )
    }
}

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ratingMachine: null,
            ratingCocktail: null,
            ratingSoftware: null,
            ratingOverall: null,
            feedbackText: "",
            contactMail: "",
            name: "",
            strings: strings[this.props.language],
            key: Math.random()
        };

        this.handleRating = this.handleRating.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.sendFeedback = this.sendFeedback.bind(this);
    }

    setDefaultState() {
        this.setState({
            ratingMachine: null,
            ratingCocktail: null,
            ratingSoftware: null,
            ratingOverall: null,
            feedbackText: "",
            contactMail: "",
            name: "",
            strings: strings[this.props.language],
            key: Math.random()
        })
    }

    componentDidUpdate(prevProps) {
        // Desired operations: ex setting state
        if (prevProps.language !== this.props.language) {
            this.setState({strings: strings[this.props.language]})
        }
    }

    handleRating(id, value) {
        alert(value);
        let feedbackRating = this.state.feedbackRating.slice(0);
        feedbackRating[id] = value;
        this.setState({feedbackRating: feedbackRating})
    }

    handleChange = (event) => {
        let name = event.target.name;
        this.setState({
            [name]: event.target.id
        });
    };

    handleTextChange = (event) => {
        let name = event.target.name;
        this.setState({
            [name]: event.target.value
        });
    };

    sendFeedback() {

        if(this.state.ratingMachine == null) {
            alert('Please rate the machine.');
            return;
        }
        else if(this.state.ratingSoftware == null) {
            alert('Please rate the software.');
            return;
        }
        else if(this.state.ratingCocktail == null) {
            alert('Please rate the cocktails.');
            return;
        }
        else if(this.state.ratingOverall == null) {
            alert('Please rate overall experience.');
            return;
        }
        else if(this.state.name == null) {
            alert('Please add your name.');
            return;
        }

        //this.state.feedbackText
        axios
            .post(process.env.REACT_APP_SERVER_MODE +
                process.env.REACT_APP_SERVER_ADDRESS +
                process.env.REACT_APP_SERVER_PORT +
                "/api/feedback",
                {
                    ratingMachine: this.state.ratingMachine,
                    ratingCocktail: this.state.ratingCocktail,
                    ratingSoftware: this.state.ratingSoftware,
                    ratingOverall: this.state.ratingOverall,
                    feedbackText: this.state.feedbackText,
                    contactMail: this.state.contactMail,
                    name: this.state.name,
                },
            ).then(response => {
                console.log(response)
                alert('Thank you very much for your Feedback')
                this.setDefaultState();
            })
            .catch(error => {
                console.log(error)
                //alert(error.response.data['Error']);
            });
    }

    render() {
        //console.log(this.props.language)
        //strings.setLanguage('en');
        return (
            <div style={{paddingTop: '20px'}}>
                <Container className="box">
                    <Row key={this.state.key}>
                        <Col>
                            <h3>{this.state.strings.rateProduct}</h3>
                            <br/>
                            <Form>
                                <h4>{this.state.strings.satisfaction}</h4>
                                <FormGroup tag="fieldset" inline>
                                    <legend className="form-group required control-label">{this.state.strings.machine}</legend>
                                    <RatingBuilder identifier="Machine" handler={this.handleChange}> </RatingBuilder>
                                </FormGroup>
                                <FormGroup tag="fieldset" inline>
                                    <legend className="form-group required control-label">{this.state.strings.software}</legend>
                                    <RatingBuilder identifier="Software" handler={this.handleChange}> </RatingBuilder>
                                </FormGroup>
                                <FormGroup tag="fieldset" inline>
                                    <legend className="form-group required control-label">{this.state.strings.cocktail}</legend>
                                    <RatingBuilder identifier="Cocktail" handler={this.handleChange}> </RatingBuilder>
                                </FormGroup>
                                <FormGroup tag="fieldset" inline>
                                    <legend className="form-group required control-label">{this.state.strings.overall}</legend>
                                    <RatingBuilder identifier="Overall" handler={this.handleChange}> </RatingBuilder>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="feedbackMail">{this.state.strings.interested}</Label>
                                    <Input type="email" name="contactMail" onChange={this.handleTextChange}
                                           placeholder="example@example.com" maxlength="50"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="feedbackText">{this.state.strings.moreFeedback}</Label>
                                    <Input type="textarea" name="feedbackText" onChange={this.handleTextChange} maxlength="500"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="name" className="form-group required control-label">{this.state.strings.name}</Label>
                                    <Input type="text" name="name" onChange={this.handleTextChange} reqired placeholder="John Doe" maxlength="20"/>
                                </FormGroup>
                                <Button onClick={this.sendFeedback}>{this.state.strings.submit}</Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}


export default Feedback;
