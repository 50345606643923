const data = {
    en:{
        satisfaction:"Satisfaction",
        rateProduct: "Please rate our product",
    },
    de: {
        satisfaction:"Zufriedenheit",
        rateProduct: "Bitte bewerte unser Produkt",
    },
    ru: {
        satisfaction:"удовлетворение",
        rateProduct: "Пожалуйста, оцените наш продукт",
    }
};

export{data};
