import React, {Component} from "react";
import axios from "axios";
import moment from "moment";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledCollapse
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar, faStarHalf, faUsers} from "@fortawesome/free-solid-svg-icons";
import IngredientList from "./IngredientList";
import LocalizedStrings from "react-localization";
import {data} from "./languageData";

let strings = new LocalizedStrings({data});


class LatestOrder extends Component {
    //ToDo rewrite to function
    constructor(props) {
        super(props);
        this.state = {
            strings: strings[this.props.language]
        };
    }

    componentDidUpdate(prevProps) {
        // Desired operations: ex setting state
        if (prevProps.language !== this.props.language) {
            this.setState({strings: strings[this.props.language]})
        }
    }

    render() {
        let agotext;

        if (this.props.latest_order == null) {
            agotext = this.state.strings.noOrder;
        } else {
            let date = moment.utc(this.props.latest_order);
            agotext = <div>{this.state.strings.lastOrder} {date.from(new Date())}</div>
        }
        return (
            <div>
                {agotext}
            </div>
        )
    }
}

class CocktailCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            modalSettings: false,
            popoverOpen: false,
            strings: strings[this.props.language]
        };

        this.toggle = this.toggle.bind(this);
        this.toggleSettings = this.toggleSettings.bind(this);
        this.togglePopover = this.togglePopover.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        // Desired operations: ex setting state
        if (prevProps.language !== this.props.language) {
            this.setState({strings: strings[this.props.language]})
        }
    }

    handleClick() {
        let amount = document.getElementById('amount_' + this.props.cocktail.id).value;

        console.log(amount);

        this.orderCocktail(this.props.cocktail.id, amount).then( result => {
                console.log("order handled");
            }
        );
    }

    async orderCocktail(cocktail_id, amount) {
        await axios.post(process.env.REACT_APP_SERVER_MODE +
            process.env.REACT_APP_SERVER_ADDRESS +
            process.env.REACT_APP_SERVER_PORT +
            '/api/order/',
            {
                cocktail_id: cocktail_id,
                amount: parseInt(amount)
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        ).then(response => {
            if (response.data.success === false) {
                alert(this.state.strings.tryAgainLater)
            } else {
                alert(this.state.strings.orderSuccessful)
            }
        }).catch(error => console.log(error));
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleSettings() {
        this.setState({
            modalSettings: !this.state.modalSettings
        });
    }

    togglePopover() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    render() {
        moment.locale(this.props.language);
        return (
            <Card className="content card">
                {/*<CardImg top width="100%" src="https://placeholdit.imgix.net/~text?txtsize=33&txt=256%C3%97180&w=256&h=180" alt="Card image cap" />*/}
                <CardHeader onDoubleClick={this.toggleSettings}>
                    {this.props.cocktail.name}
                </CardHeader>
                <CardBody>
                    <div>
                        <i className="fa gray"><FontAwesomeIcon
                            icon={faUsers}/> {this.state.strings.serves}: {this.props.cocktail.orders}</i>
                    </div>
                    {/*<div className="stars">
                        <div><i className="fa"><FontAwesomeIcon icon={faStar}/></i></div>
                        <div><i className="fa"><FontAwesomeIcon icon={faStar}/></i></div>
                        <div><i className="fa"><FontAwesomeIcon icon={faStar}/></i></div>
                        <div><i className="fa"><FontAwesomeIcon icon={faStar}/></i></div>
                        <div><i className="fa"><FontAwesomeIcon icon={faStarHalf}/></i></div>
                    </div>
        <br/>*/}
                    <Button href={'#' + this.props.cocktail.id} onClick={this.toggle} alt=""
                       color="info">{this.state.strings.order}</Button>
                    <br/>
                    <LatestOrder latest_order={this.props.cocktail.latest_order} language={this.props.language}/>

                    <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                        <ModalHeader toggle={this.toggle}>{this.props.cocktail.name}</ModalHeader>
                        <ModalBody>
                            <div>
                                <img src={require("../../glasmachine.png")} alt="" style={{
                                    height: '200px',
                                    display: 'block',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}/>
                                <br/>
                                <Badge color="warning">{this.state.strings.pleaseTakeCare}</Badge>
                            </div>
                            <br/>
                            {this.state.strings.ingredients}
                            <div>
                                <Button color="info" id="toggler" style={{marginBottom: '1rem'}}>
                                    {this.state.strings.details}
                                </Button>
                                <UncontrolledCollapse toggler="#toggler">
                                    <IngredientList ingredients={this.props.cocktail.ingredients}
                                                    language={this.props.language}/>
                                </UncontrolledCollapse>
                            </div>
                            <br/>
                            <div>
                                {this.state.strings.selectMachine}:
                                <Input type="select" disabled>
                                    <option value="1">Machine 10</option>
                                </Input>
                            </div>
                            <div>
                                {this.state.strings.cocktailSize}:
                                <Input type="select" id={'amount_' + this.props.cocktail.id}>
                                    <option value="250">250 ml</option>
                                    <option value="200">200 ml</option>
                                    <option value="150">150 ml</option>
                                    <option value="100">100 ml</option>
                                    <option value="50">50 ml</option>
                                </Input>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={(event) => {
                                this.handleClick();
                                this.toggle();
                            }}>{this.state.strings.order}</Button>{' '}
                            <Button color="secondary" onClick={this.toggle}>{this.state.strings.cancel}</Button>
                        </ModalFooter>
                    </Modal>
                </CardBody>
            </Card>
        )
    }
}

export default CocktailCard;
