import React, {Component} from "react";
import {ListGroup, ListGroupItem} from "reactstrap";

import LocalizedStrings from "react-localization";
import {data} from "./languageData";

let strings = new LocalizedStrings({data});

class IngredientList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            strings: strings[this.props.language]
        };
    }

    componentDidUpdate(prevProps) {
        // Desired operations: ex setting state
        if (prevProps.language !== this.props.language) {
            this.setState({strings: strings[this.props.language]})
        }
    }

    render() {
        return (
            <div>
                <ListGroup flush>
                    {
                        this.props.ingredients && this.props.ingredients.map(function (ingredient) {
                            return (
                                <ListGroupItem
                                    className="justify-content-between">{ingredient.base_ingredient.name} {ingredient.amount / 100.0 * 250} ml / {ingredient.amount}%</ListGroupItem>
                            );
                        })
                    }
                </ListGroup>
            </div>
        )
    }
}

export default IngredientList;
