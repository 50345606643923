import React, {Component} from 'react';

import '../../App.css';

import {
    ListGroupItem,
    Container
} from 'reactstrap';
import axios from "axios";

import PumpConfig from "./PumpConfig";

import './pumps.css';

import LocalizedStrings from 'react-localization';
import { data } from './languageData.js'
let strings = new LocalizedStrings({data});

const Pump = ({pump, language, ingredients, updateHandler, updatePumpStatus}) => {
    return (
        <div className="pump-item">
            <span className="id">
                {pump.id}
            </span>
            <div>
                <button onClick={() => updatePumpStatus(pump.id, !pump.active)}>{pump.active ? "Yes" : "No"}</button>
            </div>
            <div>
                {pump.flow_rate}
            </div>
            <div className="cutstring">
                {Object.keys(ingredients).length > 0 ? ingredients[pump.base_ingredient].name : pump.base_ingredient}
            </div>
            <div>
                <PumpConfig pump={pump} language={language} ingredients={ingredients} updateHandler={updateHandler}/>
            </div>
        </div>
    )
};

class Pumps extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pumps: [],
            ingredients: {},
            strings: strings[this.props.language]
        };

        // Configure functions
        this.setDefaultState = this.setDefaultState.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.updateHandler = this.updateHandler.bind(this);
        this.updatePumpStatus = this.updatePumpStatus.bind(this);
    }

    componentDidMount() {
        this.setDefaultState()
    }

    componentDidUpdate(prevProps){
        // Desired operations: ex setting state
        if(prevProps.language !== this.props.language) {
            this.setState({strings: strings[this.props.language]})
        }
    }

    async updatePumpStatus(pumpId, status) {
        await axios.put(process.env.REACT_APP_SERVER_MODE +
            process.env.REACT_APP_SERVER_ADDRESS +
            process.env.REACT_APP_SERVER_PORT +
            '/api/pump/' + pumpId.toString(),
            {
                active: status
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        ).then(response => {
            console.log(response);
            this.updatePumpList();
        }).catch(error => console.log(error));
    }

    /**
     * Updates the current list of pumps
     */
    async updatePumpList() {

        axios
            .get(process.env.REACT_APP_SERVER_MODE +
                process.env.REACT_APP_SERVER_ADDRESS +
                process.env.REACT_APP_SERVER_PORT +
                "/api/pump"
            ).then(response => {

            const newPumps = response.data.map(c => {
                return {
                    id: c.id,
                    active: c.active,
                    base_ingredient: c.base_ingredient,
                    gpio: c.gpio,
                    flow_rate: c.flow_rate
                };
            });

            this.setState({
                pumps: newPumps
            });
            })
            .catch(error => console.log(error));
    }

    async updateIngredientList() {

        axios
            .get(process.env.REACT_APP_SERVER_MODE +
                process.env.REACT_APP_SERVER_ADDRESS +
                process.env.REACT_APP_SERVER_PORT +
                "/api/baseingredient"
            ).then(response => {
                let ingredients = {};

                response.data.forEach(c => {
                    ingredients[c.id] = c;
                }, this);

                this.setState({
                    ingredients: ingredients
                });
            })
            .catch(error => console.log(error));
    }

    setDefaultState() {
        this.setState({
            pumps: [],
            ingredients: {}
        });
        this.updateHandler();
    }

    updateHandler() {
        this.updatePumpList();
        this.updateIngredientList();
    }

    render() {
        let ingredients = this.state.ingredients;

        return (
            <div style={{paddingTop: '20px'}}>
                <Container className="box">
                        <div className="pump-box">
                            <ListGroupItem className="justify-content-between">
                                <div className="pump-item">
                                    <span className="id">
                                        ID
                                    </span>
                                    <div>
                                        {this.state.strings.active}
                                    </div>
                                    <div>
                                        {this.state.strings.flowRate}
                                    </div>
                                    <div>
                                        {this.state.strings.ingredient}
                                    </div>
                                </div>
                            </ListGroupItem>
                            {
                                this.state.pumps.map(function (pump, i) {
                                        return (
                                            <ListGroupItem key={"ListGroupItemPump" + pump.id}
                                                           className="justify-content-between">
                                                <Pump key={"Pump" + pump.id} pump={pump} language={this.props.language}
                                                      ingredients={ingredients}
                                                      updateHandler={this.updateHandler}
                                                      updatePumpStatus={this.updatePumpStatus}/>
                                            </ListGroupItem>
                                        );
                                }, this)
                            }
                        </div>
                    {/*<Button onClick={this.toggleModal}>{this.state.strings.newpump}</Button>*/}
                </Container>
            </div>
        )
    }
}

export default Pumps;
